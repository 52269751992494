<template>
  <div>
  <div class="card-block">
   <div class="card-inner" :class="{'backface-visible':rotateDescription}">
  <div class="vlt-plans-main card-frontface" >
    <div class="trapezoid">
      <span class="tag">{{planDetailsContent.tag}}</span>
    </div>
    <div class="vlt-plans">
      <div class="vlt-amount" v-if="price">
        <div class="currency-symbol">{{currencySymbol}}</div>
        <div class="amount">{{ price }}</div>
      </div>
      <div v-if=" planDetailsContent.renewperiod && planDetailsContent.renewperiod !== 'MONTHLY'">
        <p class="vlt-period">{{ planDetailsContent.renewperiod }}</p>
      </div>

      <div v-else class="monthly-yearly" @click="actDropdown(planDetailsContent.planname)">
        <p
          class="vlt-period"
        >{{ renewPeriod[planDetailsContent.planname].charAt(0).toUpperCase() + renewPeriod[planDetailsContent.planname].slice(1).toLowerCase() }}</p>
        <img alt="Arrow" src="@/assets/icons/dropdownArrow.svg" loading="lazy" />
      </div>

      <PlansDropdown
        v-if="planDetailsContent.planname === displayDropdown? true: false"
        :dropdownItems="dropdownItems"
        :content="planDetailsContent"
      />

      <p class="vlt-plantype">{{ planDetailsContent.plantype }}</p>
      <div class="vlt-description-main restricted" ref="descriptionParent">
        <div class="decription-holder" ref="descriptionChild">
          <p class="vlt-description">{{ description }}</p>
          <div class="description-points" v-for="(desc, key) in descriptionArray" :key="key">
            <div class="checkmark checkmark-div">
              <div class="checkmark_stem"></div>
              <div class="checkmark_kick"></div>
            </div>
            <div class="desc-points">{{(desc).trim()}}</div>
          </div>
        </div>
        <div class="view-more" v-if="showViewButton"><button @click="rotateDescription=true;">View More</button></div>
      </div>

      <div class="subs-btns" v-show="displayBtn && btnName">
        <button
          type="submit"
          class="vlt-btn btn-login btn-lg-primary inverted button-click-hover"
          @click="actMultipleFunctions"
        >{{ btnName }}</button>
      </div>
      <div>
        <div v-if="expiry" class="main-dot" @click="actTrialSubscribe">
          <!-- <div class="dot"> -->
          <!-- <div :class="expiry? 'dot': ''"></div> -->
          <div class="expiry">{{expiry}}</div>
          <!-- </div> -->
        </div>
      </div>
    </div>
  

    <!-- <SuccessPopup
      v-if="toggleSuccess"
      :closePopupCB="() => toggleSuccessPopup(false)"
      :message="message"
    ></SuccessPopup>-->
  </div>
  <div class="card-backface">
    <div class="vlt-description-main backface-description">
      <div class="description-points" v-for="(desc, key) in descriptionArray" :key="key">
              <div class="checkmark checkmark-div">
                <div class="checkmark_stem"></div>
                <div class="checkmark_kick"></div>
              </div>
              <div class="desc-points">{{(desc).trim()}}</div>
      </div>
      <div class="view-more view-back">
        <button @click="rotateDescription=false;">back</button>
      </div>
    </div>
  </div>
    </div><!--card-inner -->
  </div><!--card-block-->
    <div class="cancel" v-if="cancelPlansBtn && plansSubscribed">
      <button type="submit" class="cancel-btn" @click="toggleConfirmPopup(true)">Cancel plan</button>
    </div>

    <ConfirmPopup
      v-if="toggleConfirm && cancelPlansBtn && plansSubscribed"
      :userplanid="planDetailsContent.userplanid"
      :closePopupCB="() => toggleConfirmPopup(false)"
    ></ConfirmPopup>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { EventBus } from "@/main";
import appMixins from "@/mixins/appMixins";
import { formatDateString } from '@/mixins/dateSupport';

export default {
  data() {
    return {
      expiry: "",
      btnName: "",
      displayBtn: true,
      currencySymbol: undefined,
      cancelPlansBtn: undefined,
      description: "",
      displayDropdown: "",
     // dropdownItems: [],
      renewPeriod: [],
      price: null,
      toggleConfirm: false,
      trialPeriod: "",
      // toggleSuccess: false,
      message: "",
      planPeriod: "",
      descriptionArray: [],
      planResponse: null,
      flagAutologin: true,
      descriptionObserver:null,
      showViewButton:false,
      rotateDescription:false
    };
  },
  props: [
    "planDetailsContent",
    "cancelPlans",
    "plansSubscribed",
    "planActivated",
    "fromPage",
    "trialPlan",
    "expiryStatus"
  ],
  computed: {
    ...mapGetters(["subscriberId", "plansSubscribedResponse"]),
    dropdownItems(){
      const items = [] ;
      ( this.planDetailsContent?.monthlydiscountedprice > 0 ) && items.push( { title: "Monthly" } );
      ( this.planDetailsContent?.yearlydiscountedprice > 0 ) && items.push( { title: "Yearly" } );

      return items ;
    }
  },
  methods: {
    ...mapActions(["actProfileUpdate", "actGetPlans"]),
    // createDropdownList() {
    //   this.dropdownItems = [{ title: "Monthly" }, { title: "Yearly" }];
    // },
    selectedDropdown(data) {
      console.log( data.plantype, this.renewPeriod[this.planDetailsContent.planname] );
      if (
        data.plantype !== this.renewPeriod[this.planDetailsContent.planname]
      ) {
        this.renewPeriod[data.content.planname] = data.plantype;
        this.planPeriod = data.plantype;
        this.displayDropdown = "";
       // if (this.plansSubscribed) {
          if (data.plantype === "Monthly") {
            this.price = data.content.monthlydiscountedprice / 100;
             // data.content.monthlyprice / 100;
          } else {
            this.price = data.content.yearlydiscountedprice / 100;
              //data.content.yearlyprice / 100;
          }
        // } else {
        //   if (data.plantype === "Monthly") {
            
        //     this.price[data.content.planname] = data.content.monthlyprice / 100;
        //   } else {
        //     this.price[data.content.planname] = data.content.yearlyprice / 100;
        //   }
        // }
      }
    },
    actDropdown(contentId) {
      if (this.displayDropdown && contentId === this.displayDropdown) {
        this.displayDropdown = "";
      } else {
        this.displayDropdown = contentId;
      }
    },
    descriptionSet() {
      if (
        this.planDetailsContent.description.details ||
        this.planDetailsContent.description.details == ""
      ) {
        this.description = this.planDetailsContent.description.details;
      } else {
        this.description = this.planDetailsContent.description;
      }

      if (
        this.planDetailsContent.description.points &&
        this.planDetailsContent.description.points.length > 0
      ) {
        this.descriptionArray = this.planDetailsContent.description.points;
      }

      // if (this.plansSubscribed) {

      if (this.plansSubscribed && this.planDetailsContent.renewperiod) {
        this.renewPeriod[
          this.planDetailsContent.planname
        ] = this.planDetailsContent.renewperiod;
      } else {
        this.renewPeriod[
          this.planDetailsContent.planname
        ] = this.dropdownItems[0].title;
      }

    },
    toggleConfirmPopup(state) {
      EventBus.$emit("ConfirmPopup", state);
      this.toggleConfirm = state;
    },
    actCancel(planid) {
      if (planid === this.planDetailsContent.userplanid) {
        // console.log("entered cancel");
        let data = {};
        data.subscriberid = this.subscriberId;
        data.planstatus = "CANCEL";
        data.planid = planid;
        this.actProfileUpdate(data)
          .then(response => {
            this.cancelPlansBtn = false;
            this.btnName = "REACTIVE";
            this.expiry = ""
            // EventBus.$emit("changedStatus", true);
          })
          .catch(error => {
            console.log("error ", error);
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.actCancel(planid));
                this.flagAutologin = false;
                return;
              }
            }
          });
      }
    },
    actMultipleFunctions() {
      if (this.btnName === "REACTIVE") {
        console.log("planid ", this.planDetailsContent)
        let data = {};
        data.subscriberid = this.subscriberId;
        data.planstatus = "ACTIVE";
        data.planid = this.planDetailsContent.userplanid;
        this.actProfileUpdate(data)
          .then(response => {
            // this.btnStatus();
            this.cancelPlansBtn = true;
            this.btnName = "RENEW";
          if(this.planDetailsContent.expirydate){
            this.expiry = this.expiryDetails(this.planDetailsContent.expirydate)
          }

            // EventBus.$emit("changedStatus", true);
          })
          .catch(error => {
            console.log("error ", error);
          });
        // } else if (this.btnName === "SUBSCRIBE" && this.fromPage === "payment") {
      } else {
        let param = {};
        param.data = "payment";
        param.content = this.planDetailsContent;
        if (this.planPeriod) {
          param.selectedtype = this.planPeriod;
        } else if (this.planDetailsContent.renewperiod) {
          param.selectedtype = this.planDetailsContent.renewperiod;
        } else {
          param.selectedtype = this.renewPeriod[
            this.planDetailsContent.planname
          ];
        }
        param.frompage = "subscribe";
        if (this.btnName === "SUBSCRIBE") {
          param.subscriptiontype = "NEW";
        } else {
          param.subscriptiontype = this.btnName;
        }

        param.amount = this.price * 100;

        param.planSubscribed = this.plansSubscribedResponse;
        if (this.frompage !== "payment") {
          param.frompage = "plans";
          let data = {
            payment: true,
            content: this.planDetailsContent,
            paymentdata: param
          };

          localStorage.setItem("retry", JSON.stringify(data));
          
          EventBus.$emit("paymentInitPopup", data);

          EventBus.$emit("paymentInitPopup", data);
        }
        EventBus.$emit("checkDisplayPayment", param);
      }
    },
    btnStatus() {
      this.cancelPlansBtn = this.cancelPlans;
      if (
        this.planDetailsContent.plantype === "PREMIUM" &&
        !this.cancelPlansBtn
      ) {
        this.btnName = "UPGRADE";
      }
      if (
        this.plansSubscribed &&
        this.planDetailsContent.planstatus === "ACTIVE"
      ) {
        this.btnName = "RENEW";
      } else if (!this.planActivated) {
        this.cancelPlansBtn = false;
        this.btnName = "SUBSCRIBE";
      }

      // if (!this.btnName) {
      //   this.btnName = "SUBSCRIBE";
      // }

      if (this.planDetailsContent.currency === "INR") {
        this.currencySymbol = "₹";
      } else {
        this.currencySymbol = "$";
      }
      // if (this.planDetailsContent.planstatus) {
      //   console.log("cancel ", this.planDetailsContent.planstatus);
      // }
      if (
        this.planDetailsContent.planstatus &&
        this.planDetailsContent.planstatus === "CANCEL"
      ) {
        this.btnName = "REACTIVE";
      }
    },
    expiryStatusFunction() {
      if (this.planDetailsContent.expirydate) {
        if (this.planDetailsContent.planstatus === "ACTIVE") {
          if(this.planDetailsContent.expirydate){
            this.expiry = this.expiryDetails(this.planDetailsContent.expirydate)
          }

          if (
            (this.planDetailsContent.renewperiod === "MONTHLY" &&
              diffDays <= 30 &&
              this.btnName === "RENEW") ||
            this.btnName == "UPGRADE " ||
            (this.planDetailsContent.renewperiod === "YEARLY" &&
              diffDays <= 365 &&
              this.btnName === "RENEW")
          ) {
            this.displayBtn = true;
          }
        } else if (
          this.planDetailsContent.expirydate &&
          this.planDetailsContent.planstatus !== "CANCEL" &&
          !this.expiryStatus
        ) {
          let currentDate = new Date();
          let date = new Date( formatDateString( this.planDetailsContent.expirydate ) );

          if (date < currentDate) {
            this.expiry = "";
          } else {
            let today = new Date( formatDateString( this.planDetailsContent.expirydate ) );
            let month = today.toLocaleString("default", { month: "short" });

            let date = today.getDate();
            this.expiry = "Free Trial Expires on " + date + " " + month;
          }
        }
      } else if (
        this.planDetailsContent.trialperiod &&
        !this.trialPlan &&
        !this.planActivated &&
        !this.expiryStatus
      ) {
        console.log("status ", this.expiryStatus)
        this.trialPeriod = parseInt(this.planDetailsContent.trialperiod);
        if (this.trialPeriod > 0) {
          this.expiry = "Start FREE TRIAL for " + this.trialPeriod + " days";
        }
      }
    },
    actTrialSubscribe() {
      if (this.trialPeriod > 0) {
        let data = {};
        data.subscriberid = this.subscriberId;
        data.planid = this.planDetailsContent.userplanid;
        data.planmode = "TRIAL";
        data.planstatus = "TRIAL";
        let planperiod;
        if (this.renewPeriod[this.planDetailsContent.planname]) {
          planperiod = this.renewPeriod[this.planDetailsContent.planname];
        } else {
          planperiod = this.planDetailsContent.renewperiod;
        }
        data.planperiod = planperiod.toUpperCase();

        data.trialperiod = this.trialPeriod;
        this.actProfileUpdate(data)
          .then(response => {
            if (!response.error) {
              // this.toggleSuccess = true;

              EventBus.$emit("paymentInitPopupClose");

              this.message =
                "Your subscription trial pack activated effective today .You may continue watching content till your trial expires";

              let obj = {};
              obj.errorMessage = this.message;
              obj.page = "Home";
              EventBus.$emit("success", obj);
              EventBus.$emit("planPurchased",true);  
              let data = {};
              data.subscriberid = this.subscriberId;
              this.actGetPlans(data)
                .then(response => {
                  if (!response.error) {
                    let today = new Date( formatDateString( response.expirydate ) );
                    let month = today.toLocaleString("default", {
                      month: "short"
                    });

                    let date = today.getDate();
                    this.expiry = "Free Trial Expires on " + date + " " + month;
                  }
                })
                .catch(error => {
                  console.log("error ", error);
                  if (error.status === 401) {
                    // this.unauthorisedHandler();
                    if (this.flagAutologin) {
                      this.commonAutoLogin(this.actTrialSubscribe);
                      this.flagAutologin = false;
                      return;
                    }
                  }
                });
            } else {
              let obj = {};
              obj.errorMessage = response.reason;
              EventBus.$emit("failure", obj);
            }
          })
          .catch(error => {
            console.log("error ", error);
            if (error.status === 401) {
              // this.unauthorisedHandler();
              if (this.flagAutologin) {
                this.commonAutoLogin(this.actTrialSubscribe);
                this.flagAutologin = false;
                return;
              }
            }
          });
      }
    },
    attachDescriptionObserver(){
      this.descriptionObserver=new ResizeObserver(()=>{
          let child=this.$refs.descriptionChild;
          let parent=this.$refs.descriptionParent;
          if(!parent || !child)return;
          if((child.offsetHeight-parent.offsetHeight)>20){
            this.showViewButton=true;
          }
          else this.showViewButton=false;
      });
      this.descriptionObserver.observe(this.$refs.descriptionChild);
    }
  },
  created() {
    // console.log("userplanid ", this.planDetailsContent);
    if( this.dropdownItems.length ){
      const param = {
          content : this.planDetailsContent,
          plantype : this.dropdownItems[0].title 
      }
      this.selectedDropdown( param );
    }
    //this.createDropdownList();
    this.btnStatus();
    this.expiryStatusFunction();
    this.descriptionSet();

    //event bindings:
    EventBus.$on("selectedDropdown", this.selectedDropdown);
    EventBus.$on("confirmedPopup", data => {
      this.actCancel(data.userplanid);
    });
  },
  mounted(){
    this.attachDescriptionObserver();
  },
  beforeDestroy(){
    this.descriptionObserver && this.descriptionObserver.disconnect();
  },
  components: {
    PlansDropdown: () =>
      import(
        /* webpackChunkName: "dropdown" */ "@/components/Popups/payment/plansDropDown.vue"
      ),
    ConfirmPopup: () =>
      import(
        /* webpackChunkName: "changepassword" */ "@/components/Popups/confirmation.vue"
      )
    // SuccessPopup: () =>
    //   import(
    //     /* webpackChunkName: "changepassword" */ "@/components/Popups/success.vue"
    //   )
  },
  mixins: [appMixins]

};
</script>

<style lang="scss" scoped>
@import "~sass/modules/_variables.scss";
@import "~sass/modules/_fonts.scss";

.hide-view{
  display:none !important;
}
.card-block{
  background-color: transparent;
  width: 100%;
  height: max-content;
  // border: 1px solid #f1f1f1;
  perspective: 1000px;
    .card-inner{
      position: relative;
      width: 100%;
      height: 32rem;
      text-align: center;
      font-family: $font-family;
      font-weight: $font-weight-regular;
      transition: transform 0.5s;
      transform-style: preserve-3d;
      box-shadow: 0px 3px 6px $clr-color-black-3;
      border-radius: 5px;
      border:2px solid transparent;
      &:hover{
        border: 2px solid $theme-primary;
      }
      .card-frontface, .card-backface {
          position: absolute;
          top:0;
          left:0;
          width: 100%;
          height:100%;
          overflow:hidden;
          -webkit-backface-visibility: hidden; 
          backface-visibility: hidden;
          background: $clr-color-black-2 0% 0% no-repeat padding-box;
          border-radius: 5px;
        }
      .card-backface{
        background: $clr-color-black-2 0% 0% no-repeat padding-box;
        display:flex;
        flex-flow:row nowrap;
        align-items:center;
        justify-content: center;
        transform: rotateY(180deg);
        } 
      
      &.backface-visible{
          transform: rotateY(180deg);
        }
    }

  
}

.vlt-plans-main {
  height: max-content;
  // .trapezoid {
  //   border-bottom: 21px solid $theme-primary;
  //   border-left: 30px solid transparent;
  //   border-right: 15px solid transparent;
  //   background-color:$theme-primary;
  //   width: 146px;
  //   left: -36px;
  //   top: 45px;
  //   position: absolute;
  //   transform: rotate(-55deg);
  //   display: flex;
  //   justify-content: center;
  //   -webkit-backface-visibility: hidden;
  //   backface-visibility: hidden;
  //   .tag {
  //     position: absolute;
  //     font-size: 10px;
  //     top: 3px;
  //     color: $btn-font-color;
  //     font-family: $font-family;
  //     font-weight: $font-weight-semibold;
  //   }
  // }
  .trapezoid{
    background-color: $theme-primary;
    height: 1.5rem;
    width: 250px;
    transform: rotate(-50deg) translate(-40%, -180%);
    position: absolute;
    top: -1.5rem;
    left: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    .tag{
      display:block;
      font-size:10px;
      line-height:1.5rem;
      height:1.5rem;
      color: $btn-font-color;
      font-family: $font-family;
      font-weight: $font-weight-semibold;
    }
  }
  .vlt-plans {
    // top: 221px;
    // width: 35%;
    height: max-content;
  
    opacity: 1;
    text-align: center;
    // padding: 20px 0px;
    padding: 1.5rem 0px;

    .vlt-amount {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      color: $font-clr-white;
      display: flex;
      justify-content: center;
      padding-right: 24%;
      margin-left: 70px;
      height:4rem;
      .currency-symbol {
        font-size: 2rem;
        line-height: 3.5rem;
        vertical-align: middle;
        // margin-top: 5px;
        margin-right: 0.5rem;
      }
      .amount {
        font-size: 3rem;
        line-height: 4rem;
        vertical-align: middle;
        // font-size: 35px;
      }
    }
    .monthly-yearly {
      cursor: pointer;
      display: flex;
      justify-content: center;
      max-height:3.5rem;
    }
    .vlt-period {
      padding: 1rem 0%;
      font-size: 1rem;
      line-height: 1.5rem;
    }
  }
}
    .vlt-description-main {
      //padding: 0 5px;
      padding: 0 clamp(5px,2vw,30px);
      text-align: left;
      position:relative;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      &.restricted{
        overflow:hidden;
        height: 12.5rem;
      }
      &.backface-description{
        overflow-y:auto;
        overflow-x:hidden;
        display:flex;
        flex-flow:column nowrap;
        justify-content: center;
        height:100%;
      }
      .view-more{
        height:1.5rem;
        width:100%;
        text-align:center;
        position:absolute;
        bottom:0;
        left:0;
        background-color:$clr-color-black-2;
        &.view-back{
          position:static;
          margin:1rem 0;
          background-color:transparent; 
        }
        button{
          border:0;
          background:none;
          color:$tag-primary;
          font-size:1rem;
          line-height:1.5rem;
          font-family: $font-family;
          font-weight: $font-weight-regular;
          cursor:pointer;
        }
      }
    }
    .vlt-description {
      margin-top: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      height:3rem;
      overflow:hidden;
      // padding: 0px 20%;
      text-align: center;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
    .description-points {
      display: flex;
      width: 100%;
      margin: 0 auto;
      margin-top:1rem;
      font-size: 1rem;
      line-height:1.5rem;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
    // .tick {
    //    position: absolute;
    // width:3px;
    // height:3px;
    // background-color:#ccc;
    // left:8px;
    // top:12px;
    // }
    .desc-points {
      width: 70%;
    }
    .checkmark-div {
      width: 30%;
    }
    .checkmark {
      display: inline-block;
      width: 30px;
      height: 22px;
      -ms-transform: rotate(45deg); /* IE 9 */
      -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
      transform: rotate(45deg);
    }

    .checkmark_stem {
      position: absolute;
      width: 2px;
      height: 9px;
      background-color: #ccc;
      left: 11px;
      top: 7px;
    }

    .checkmark_kick {
      position: absolute;
      width: 2px;
      height: 3px;
      background-color: #ccc;
      left: 9px;
      top: 13px;
    }
    .subs-btns {
      // padding: 10%;
      padding: 1rem 5%;
      .btn-lg-primary{
        padding:0.5rem 1rem;
        font-size:1rem;
        line-height: 1.5rem;
        font-weight: $font-weight-regular;
      }
    }
    .vlt-plantype {
      font-family: $font-family;
      font-weight: $font-weight-semibold;
      color: $font-clr-white;
      font-size: 1.25rem;
      line-height:1.5rem;
      padding: 1rem;
      max-height:3.5rem; 
    }
    .vlt-btn {
      border: 0;
      text-transform: uppercase;
      letter-spacing: 1px;
      color: $font-clr-white;
      background: $button-primary;
      border-radius: 16px;
      opacity: 1;
    }
    .btn-login {
      padding: 5px;
      width: 70%;
      font-size: 12px;
    }
    .main-dot {
      display: flex;
      padding: 0 30px;
      justify-content: center;
      cursor: pointer;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
    .dot {
      height: 10px;
      width: 10px;
      background-color: $theme-primary;
      border-radius: 50%;
      display: inline-block;
      margin: 5px 5%;
    }
    .expiry {
      color: $theme-primary;
      font-size: 0.9rem;
      line-height:1rem;
      text-align: left;
      font-family: $font-family;
      font-weight: $font-weight-regular;
    }
  
  .cancel {
    // margin: 8px 2px;
    margin-top: 3%;
  }

// .vlt-plans:hover {
//   border: 2px solid $theme-primary;
// }
.cancel-btn {
  background-color: $clr-color-grey-1;
  color: $font-clr-white-1;
  padding: 8px;
  font-size: 14px;
  cursor: pointer;
  border: 0;
  width: 100%;
  letter-spacing: 1px;
  border-radius: 5px;
}
</style>